import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const WholeHogBBQ = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const description = "Watch me cook recipes from Sam Jones' Whole Hog BBQ cookbook, which includes Eastern NC style BBQ and traditional sides.";

  const image = getImage(first_video.fields.thumbnail);

  return (
    <Layout>
      <Seo title="Rodney Scott's World of BBQ" image={image} meta={metaData} description={description} />
      <h1>Rodney Scott's World of BBQ</h1>
      <h2>Every Day is a Good Day</h2>
      <p>
        Rodney Scott cooked his first pig at the age of 11. After more than 30 years of experience, 
        he was named Best Chef: Southeast by the James Beard Foundation in 2018. His 
        restaurant, <a href="https://rodneyscottsbbq.com/">Rodney Scott's BBQ</a>, opened in Charleston,
        South Carolina in 2017 and now (as of early 2024) has four locations in three states. There, 
        they cook whole hogs over live coals using traditional cooking techniques. This book includes
        many of the recipes served at his restaurants.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/rodney scott's world of bbq/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default WholeHogBBQ;